import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchHolidays(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/holidays", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchHolidayCalendar(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/holiday-calendar", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchHoliday(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`holidays/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchShiftsList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/shifts", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        addHoliday(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("holidays", userData)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateHoliday(ctx, holiday) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`holidays/${holiday.id}`, holiday.data,)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(values[0][0]);
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeHoliday(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`holidays/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleHolidays(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/deleteMultipleHolidays/`, { ids: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/holidays/create")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        }
    }
};
